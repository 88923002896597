<template>
    <button class="univ-btn w-100 mt-4" @click="orderProcessed" > Free Checkout</button>
</template>
<script>
import store from "@/store"
import axios from "axios"
import api from "@/services/api"
import Helper from "@/services/helper"
import router from "@/router"
export default {
    name:"Free",
    data() {
        return {
            userId: store.getters.user.id,
            payment: this.$route.params.payment,
            data:this.$route.query.data,
            paymentAmount:0,
            currency: store.getters.currency,

            successDdata:{
                data:this.$route.query.data,
                payment: 'free',
                cartData:{
                    cartId: store.getters.userCart.cart_id,
                    userId: store.getters.userCart.user_id,

                    amount: store.getters.cartTotal,
                    discount: store.getters.amountDiscount,
                    code: store.getters.discountCode,

                    cartItems:store.getters.cartItemSummary,
                }
            }
        };
    },
    methods:{
        async orderProcessed() {
            let app = this;
            Helper.showSpinner();
            await axios.post(api.getUrl("/payment/free/success"), app.successDdata ).then(function (response) {
                if (response.data.success) {
                    router.push({ name: "PaymentSuccess" }).catch(()=>{});
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        },
    }
}
</script>
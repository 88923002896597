<template>
    <div>
        <section class="innerBanner pt-1">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="back-btn mb-4">
                            <a @click="$router.go(-1)"><span class="backIcon"><span class="icon-arrow"></span></span> Back </a>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="pageTitle">
                            <h1>Checkout</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="pageWrap checkoutWrap">
            <div class="container">
                <div class="row">
                    <div class="col-xl-8">
                        <div class="cartList">
                            <h2>Order Summary</h2>
                            <ul>
                                <li v-for="(item, i) in cartItems" :key="i">
                                    <div class="itemImage">
                                        <img :src="item.thumbnail" />
                                    </div>
                                    <div class="itemContent">
                                        <div>
                                            <h3>{{ item.title }}</h3>
                                            <p>Type:<span class="brand-color">{{ item.item_type }}</span></p>
                                            <template v-if="item.is_offer">
                                                <span class="price"><del> {{ item.display_price }} </del></span>
                                                <span class="price">{{ item.display_offer_price }} </span>
                                            </template>
                                            <template v-else>
                                                <span v-if="item.price == 0" class="price"> Free </span>
                                                <span v-else class="price"> {{ item.display_price }} </span>
                                            </template>
                                        </div>
                                        <div class="cartRemove" @click="callRemoveCartPromise(item)" >
                                            <span class="icon-trash"></span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-4">
                        <div class="display-card">
                            <template v-if="cartTotalDiscount > 0"> 
                                <h3>Have Coupon?</h3>
                                <input type="text" placeholder="Enter Your Coupon Code" class="univ-input" v-model="couponCode"/>
                                <span class="error-field" v-if="formErrors.code">
                                <span class="icon-warning"></span> {{ formErrors.code }}</span>
                                <button class="univ-dark-btn py-2 mt-3" @click="applyDiscount">
                                    <b-spinner v-if="loader" small></b-spinner> Apply Coupon
                                </button>
                                <hr />
                            </template>
                            <h3>Order Summary</h3>
                            <ul class="orderSummary">
                                <li>
                                    <span>Order Price</span>
                                    <span> {{ currency }} {{ cartTotal }} </span>
                                </li>
                                <li>
                                    <div><span>Coupon Discount</span></div>
                                    <div>
                                        <span>{{ currency }} {{ amountDiscount }}</span>
                                        <span class="remove-discount" v-if="amountDiscount > 0" @click="removeDiscount">X</span>
                                    </div>
                                </li>
                                <li>
                                    <strong>Total</strong>
                                    <strong class="brand-color"> {{ currency }} {{ cartTotalDiscount }}</strong>
                                </li>
                            </ul>
                            <br />
                            <template v-if="cartTotalDiscount > 0">
                                <h3>Payment Via</h3>
                                <div class="row">
                                    <div class="col-xl-4 col-6 my-3">
                                        <div :class="{ active: isActive == 'esewa' }" class="paymentBlock py-3" @click="setPaymentType('esewa')" >
                                            <img src="https://seeklogo.com/images/E/esewa-logo-DA36F8FD2F-seeklogo.com.png"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-6 my-3">
                                        <div :class="{ active: isActive == 'khalti' }" class="paymentBlock py-3" @click="setPaymentType('khalti')" >
                                            <img src="https://seeklogo.com/images/K/khalti-logo-F0B049E67E-seeklogo.com.png"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-6 my-3">
                                        <div :class="{ active: isActive == 'visa' }" class="paymentBlock py-3" @click="setPaymentType('visa')" >
                                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/2560px-Visa_Inc._logo.svg.png"/>
                                        </div>
                                    </div>
                                </div>
                                <Payment :paymentType="paymentType" :paymentData="paymentData" />
                            </template>
                            <template v-else>
                                <div v-if="cartItems.length > 0 && cartTotalDiscount == 0" >
                                    <Free />
                                </div>
                                <div v-else >
                                    <span class="error-message"><span class="icon-warning"></span> Invalid amount </span>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import api from "@/services/api";
import store from "@/store";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";
import Payment from "@/views/Payments/Payment";
import Free from '@/views/Payments/partials/Free'
export default {
    name: "CartCheckout",
    components: { Payment,Free },
    data() {
        return {
            userId: store.getters.user.id,
            totalCartItem: store.getters.cartQuantity,
            paymentType: "",
            paymentData: [],
            currency: store.getters.currency,
            couponCode: store.getters.discountCode,
            formErrors: {
                code: "",
            },
            loader: false,
            isActive: false,

            formData: {
                amount: store.getters.cartTotalDiscount,
                tax_amount: 0,
                total_amount: store.getters.cartTotalDiscount,
                transaction_uuid: store.getters.userCart.cart_id,
            },
        };
    },
    computed: {
        ...mapGetters([
        "cartItems",
        "cartTotal",
        "amountDiscount",
        "cartTotalDiscount",
        "cartQuantity",
        ]),
    },
    created() {
        let app = this;
        app.$store.dispatch("getCartItems", app.userId);
    },
    mounted() {
        let app = this;
        document.title = "Cart Checkout";
        if (app.totalCartItem == 0) {
            router.push({ name: "Home" });
        }
        app.$bvModal.hide("AssetQuickViewModal");
        app.$root.$on("cart-item-removed", ($event) => {
            if ($event) {
                app.reqParamData();
                app.setPaymentType(app.paymentType);
            }
        });
    },
    methods: {
        ...mapActions(["removeCartItem"]),
        callRemoveCartPromise(item) {
            let app = this;
            app.removeCartItem({
                id: item.cartId,
                item_id: item.item_id,
                type: item.item_type,
            })
            .then(() => {
                app.reqParamData();
                app.setPaymentType(app.paymentType);
            })
            .catch((error) => {
                console.log("Error:", error);
            });
        },
        setPaymentType(option) {
            let app = this;
            app.isActive = option;
            app.paymentType = option;
            app.$store.dispatch("getCartItems", app.userId);
            app.formData.amount = store.getters.cartTotalDiscount;
            app.formData.total_amount = store.getters.cartTotalDiscount;

            if (app.paymentType === "esewa") {
                axios.post(api.getUrl("/payment/esewa/parameter"), app.formData).then((response) => {
                    if (response.data.success) {
                        app.paymentData = response.data.data;
                    }
                });
            }
            // Others payment
        },
        reqParamData() {
            let app = this;
            app.formData.amount = store.getters.cartTotalDiscount;
            app.formData.tax_amount = 0;
            app.formData.total_amount = store.getters.cartTotalDiscount;
            app.formData.transaction_uuid = store.getters.userCart.cart_id;
        },
        applyDiscount() {
            let app = this;
            app.loader = true;
            app.isActive = false;
            app.paymentType = "";
            app.formErrors.code = "";
            axios.post(api.getUrl("/apply/coupon"), {code: app.couponCode,userId: app.userId}).then(function (response) {
                if (response.data.success == true) {
                    app.loader = false;
                    let discount = 0;
                    if (store.getters.cartTotal > response.data.data.amount) {
                        if (response.data.data.type == "$") {
                                discount = response.data.data.discount;
                        } else {
                                discount =(store.getters.cartTotal * response.data.data.discount) / 100;
                        }
                        app.$store.dispatch("getCouponDiscount", {amount: discount,code: app.couponCode});
                        app.$store.dispatch("getCartItems", app.userId);
                    } else {
                        app.formErrors.code = "Sorry! Insufficient amount for discount.";
                    }
                } else {
                    if (response.data.message) {
                        app.loader = false;
                        app.formErrors.code = response.data.message;
                    } else if (response.data.errors.length > 0) {
                        app.loader = false;
                        response.data.errors.forEach(function (item) {
                            if (item.key == "code") {
                                app.formErrors.code = item.message;
                            }
                        });
                    }
                }
            });
        },
        removeDiscount() {
            let app = this;
            app.isActive = false;
            app.paymentType = "";
            app.couponCode = "";
            app.$store.dispatch("getCouponDiscount", { amount: 0, code: "" });
            app.$store.dispatch("getCartItems", app.userId);
        },
    },
};
</script>
